import React from "react"

export default function Challenge({ challenge }) {
  return (
    <div className="challenge">
      <div className="wrapper">
        <h2 className="pickup-sport-title challenge-title">
          Business Challenge
        </h2>
        <div className="challenge-container">
          <div className="challenge-content">
            <div className="challenge-content__text">
              The team was tasked with developing a mobile app that would allow{" "}
              parents to add their children, and organize and participate in
              children's sports activities by specifying a specific location
              after registration. However, the client had previously tried to
              develop the app themselves but ran into a problem: there were more
              ideas than the speed of development.
            </div>
            <div className="challenge-content__text">
              Namely, the team had to implement:
            </div>
            <ol className="challenge-content__list">
              <li>
                1. Availability of the app for{" "}
                <a
                  href="https://apps.apple.com/us/app/pickup-sports-for-families/id6443620529"
                  target="_blank"
                  rel="noreferrer"
                >
                  iOS
                </a>{" "}
                and{" "}
                <a
                  href="https://play.google.com/store/apps/details?id=com.pickupsports.pickup_sports&pli=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  Android
                </a>{" "}
                platforms
              </li>
              <li>2. A web app for the administrator</li>
              <li>
                3. The ability for parents to see each other's Play Pods based
                on a given location, and thus ensure that they can cooperate and
                get together with their children to play together
              </li>
              <li>
                4. Introduction of safe play places with coordinates accessible
                only to verified users{" "}
              </li>
            </ol>
          </div>
          <ul className="challenge-description">
            {challenge.description.map(item => (
              <li className="challenge-description__item" key={item.id}>
                <i
                  className={`fa ${item.icon} challenge-description__icon`}
                ></i>
                <div className="challenge-description__title">{item.title}</div>
                <div className="challenge-description__list">{item.list}</div>
              </li>
            ))}
          </ul>
          <div className="challenge-image">
            <div className="challenge-image-block">
              <img src={challenge.image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
