import {
  Cover,
  MultiFrame,
  Children,
  Phones,
  Pages,
  Entertainment,
  FunctionalityAndSafety,
  Health,
  ImprovingCreativity,
  Learning,
  Monitoring,
  Planning,
  ProblemSolvingSkills,
  Socialization,
  Video,
  PosterVideo,
  GooglePlay,
  AppStore,
} from "../../images/SingleCase/PickUpSport"

const dataForPichUpSport = {
  type: "project",
  smart: true,
  classComponent: "PickUpSport",
  nextLink: "cases/ingo/",
  prevLink: "cases/diasoft/",
  data: {
    top_banner: {
      cover: Cover,
      image: MultiFrame,
      GooglePlayImg: GooglePlay,
      AppStoreImg: AppStore,
    },
    overview: {},
    problems: {
      image: Children,
      content: [
        { id: "1", text: "Reducing physical activity is bad for your health" },
        {
          id: "2",
          text: "Parents lack time to plan activities for their children",
        },
        { id: "3", text: "Parents' worries about children playing outside" },
        {
          id: "4",
          text:
            "Children are often left at home, which leads to a boring routine",
        },
        { id: "5", text: "Lack of social contacts among children among peers" },
      ],
    },
    challenge: {
      image: Phones,
      description: [
        {
          id: 1,
          title: "Platform",
          list: "iOS, Android,\nWeb (for the\n administrator)",
          icon: "fa-th-large",
        },
        {
          id: 2,
          title: "Technology",
          list: "Flutter, AWS, Angular, Material UI, NestJS, OpenStreetMap.",
          icon: "fa-cog",
        },
        {
          id: 3,
          title: "Team",
          list:
            "1 Tech Lead \n1 Project Manager\n" +
            "2 Front Developers\n2 Backend Developers\n" +
            "1 Designer\n1 QA Engineer",
          icon: "fa-users",
        },
        {
          id: 4,
          title: "Duration",
          list: "4 months",
          icon: "fa-calendar",
        },
        {
          id: 5,
          title: "Client",
          list: "Startup",
          icon: "fa-user",
        },
      ],
    },
    solution: {
      video: Video,
      posterVideo: PosterVideo,
      stages: [
        { id: 1, text: "Registration and Authorization" },
        { id: 2, text: "Adding children" },
        { id: 3, text: "Creating a Game Pod" },
        { id: 4, text: "Find a Game Pod" },
        { id: 5, text: "My friend's invitation" },
        { id: 6, text: "Profile and Notifications" },
        { id: 7, text: "Admin app" },
        { id: 8, text: "Visitor counting" },
      ],
    },
    result: {
      image: Pages,
      listValued: [
        {
          id: 1,
          text: "1. Users (parents) create accounts and child profiles.",
        },
        {
          id: 2,
          text:
            "2. Parents carry out a sports assessment of their child so that our system classifies them according to their level.",
        },
        {
          id: 3,
          text:
            "3. Users can either find a game group (game pod) or create their own and invite others to play. Their level of play influences which groups they find. So, for example, a parent of a 3-year-old is not encouraged to play in a group with 8-year-olds.",
        },
        {
          id: 4,
          text:
            "4. The weekly recommended sports programs that the group plays every week will be preset.",
        },
      ],
    },
    benefits: {
      image: "",
      list: [
        {
          id: 1,
          icon: Health,
          title: "Health",
          text:
            "The app can be a supportive tool for supporting children's healthy lifestyles",
        },
        {
          id: 2,
          icon: Entertainment,
          title: "Entertainment",
          text:
            "Containing interesting games, the app can be a source of entertainment for children who enjoy outdoor activities",
        },
        {
          id: 3,
          icon: Socialization,
          title: "Socialization",
          text:
            "Many mobile apps for children can help them develop social skills and learn to socialize with others",
        },
        {
          id: 4,
          icon: ProblemSolvingSkills,
          title: "Problem-solving skills",
          text:
            "Apps can teach problem-solving and how to analyze complex situations",
        },
        {
          id: 5,
          icon: Learning,
          title: "Learning",
          text:
            "The application can teach children about the world around them in an interactive and engaging way",
        },
        {
          id: 6,
          icon: ImprovingCreativity,
          title: "Improving Creativity",
          text:
            "Creative tasks in the app can help develop the imagination",
        },
        {
          id: 7,
          icon: Planning,
          title: "Planning",
          text:
            "The app helps parents plan outings and other activities with their children",
        },
        {
          id: 8,
          icon: FunctionalityAndSafety,
          title: "Functionality and safety",
          text:
            "Help parents plan and organize their children's activities and keep track of their whereabouts",
        },
        {
          id: 9,
          icon: Monitoring,
          title: "Monitoring",
          text:
            "Receive notifications about the activities that children take on their devices and control content safety",
        },
        

      ],
    },
  },
  relatedProjects: [
    {
      link: "/cases/karpatska-chaika",
      id: "seagull",
      title: "Carpathian Seagull",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
}

export default dataForPichUpSport
