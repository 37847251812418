import React from "react"
export default function TopBanner({ topbanner }) {
  return (
    <div className="top-banner">
      <div className="coverPickUp" >
        <img src={topbanner.cover} alt="" />
      </div>
      <div className="wrapper">
        <div className="top-banner-container">
          <div className="top-banner-images">
            <img className="imagePickUp" src={topbanner.image} alt="" />
          </div>
          <div className="top-banner-content">
            <h1 className="top-banner-title">
              Sport Activities <br /> Mobile App
            </h1>
            <div className="top-banner-text">
              Developing a mobile app for children and their parents that
              engages them in active recreation.
            </div>
            <div className="top-banner-logos">
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.pickupsports.pickup_sports">
                <img src={topbanner.GooglePlayImg} />
              </a>
              <a target="_blank" href="https://apps.apple.com/us/app/pickup-sports-for-families/id6443620529">
                <img src={topbanner.AppStoreImg} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
