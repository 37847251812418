import React from "react"
import { Link } from "react-scroll"

export default function Result({ result }) {
  return (
    <div className="result">
      <div className="wrapper">
        <div className="result-header">
          <h2 className="pickup-sport-title result-title">
            How we helped users
          </h2>

          <div className="result-header__text">
            Here are <str-color-green>some of the advantages</str-color-green>{" "}
            of the app we were able to create:
          </div>
          <div className="result-header__list-text">
            <ul className="result-list">
              <li className="result-list__item">
                <span></span>
                <div>
                  The app{" "}
                  <str-color-green>
                    encourages physical activity
                  </str-color-green>
                  , which means
                  <str-color-green> staying fit </str-color-green> and
                  <str-color-green> improving health </str-color-green>
                </div>
              </li>
              <li className="result-list__item">
                <span></span>
                <div>
                  <str-color-green>
                    Strengthen the bond between parents and children{" "}
                  </str-color-green>
                  by allowing them to spend time together, play, and discuss
                  games
                </div>
              </li>
              <li className="result-list__item">
                <span></span>
                <div>
                  <str-color-green>
                    {" "}
                    A variety of ways to be physically active{" "}
                  </str-color-green>
                  helps children and parents find new ways
                  <str-color-green> to spend time together</str-color-green>
                </div>
              </li>

              <li className="result-list__item">
                <span></span>
                <div>
                  <str-color-green>
                    A user-friendly and accessible app
                  </str-color-green>{" "}
                  that{" "}
                  <str-color-green>
                    ensures the security and privacy{" "}
                  </str-color-green>
                  of app users to{" "}
                  <str-color-green>increase reliability</str-color-green>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <h2 className="pickup-sport-title result-title">Value delivered</h2>
        <div className="result-text">
          The end result is a fun, kid-friendly mobile app. Our team implemented
          the ability to invite friends via the link and get together at the
          appointed time and place, as well as the ability to remind them about
          upcoming events.
          <br />
          <span className="result-subtext">
            Introducing secure game locations with coordinates that are only
            available to confirmed users is a smart way to ensure the security
            and privacy of the app's users. By taking steps to vet users before
            granting them access to sensitive information, it has been possible
            to create a safe and secure community within the app.
          </span>
          <div className="d-flex flex-column-reverse flex-md-row p-0 result-valued col-12">
            <div className="p-0 col-12 col-md">
              The working process is as follows:
              <ul className="result-list">
                {result.listValued.map(item => (
                  <li key={item.id} className="result-list__item">
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>
            <div className="result-valued-image p-0 col-12 col-md-4">
              <img className="w-100" src={result.image} />
            </div>
          </div>
        </div>

        <div className="result-stores text-start text-md-center col-12">
          Read more about the apps for
          <a
            href="https://play.google.com/store/apps/details?id=com.pickupsports.pickup_sports&pli=1"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Android
          </a>{" "}
          and{" "}
          <a
            href="https://apps.apple.com/us/app/pickup-sports-for-families/id6443620529"
            target="_blank"
            rel="noreferrer"
          >
            iOS
          </a>{" "}
          in the stores
        </div>
        <div className="result-testimonial">
          <div className="result-testimonial-text result-text">
            We ended up with this customer testimonial:
            <br />
            <em className="result-testimonial-em">
              "Users have responded positively to the app's concept,
              functionality, and ease of use, thanks to OSSystem's excellent
              work. The transparent, collaborative team communicated maintained
              constant and clear communication, provided daily updates, and was
              highly receptive to feedback throughout."
            </em>{" "}
            <span className="result-testimonial-fulltext">
              But that's just part of it! You can read the full{" "}
              <a
                href="https://clutch.co/profile/ossystem#reviews"
                target="_blank"
                rel="noreferrer"
              >
                testimonial
              </a>
              , find out how our product helped them solve the problem, and gain
              extra confidence in the quality of our services.
            </span>
          </div>
        </div>
        <div className="result-contact">
          <div className="result-contact__text">
            Need expert help with your project? Contact us today to see how we
            can <br />
            assist you
          </div>
          <div className="result-contact__btn">
            <Link
              className=""
              to="anchor-form"
              spy={true}
              smooth={"easeOutSine"}
              offset={10}
              duration={1000}
            >
              Tell us more about your project
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
