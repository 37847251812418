import React from "react"

export default function Benefits({ benefits }) {
  return (
    <div className="benefits">
      <div className="wrapper">
        <h2 className="benefits-title pickup-sport-title ">Benefits</h2>
        <div className="challenge-content__text benefits-content-text">
          Developing mobile apps for children is a positive idea that helps
          children develop and learn, as well as being useful and convenient for
          their parents. Such apps are a great opportunity to create a product
          that can be useful and in demand, as they have many advantages,
          including:
        </div>
        <div className="benefits-blocks">
          {benefits.list.map(item => (
            <div key={item.id} className="benefits-blocks__item">
              <div className="benefits-blocks__image">
                <img src={item.icon} />
              </div>
              <div className="benefits-blocks__title">{item.title}</div>
              <div className="benefits-blocks__description">{item.text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
