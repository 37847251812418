import React from "react"

export default function Overview() {
  return (
    <div className="overview">
      <div className="wrapper">
        <div className="pickup-sport-title overview-title">
          Project overview
        </div>
        <div className="overview-text">
          <p>
            The rapid development of mobile technology has become an integral
            part of our daily lives. Adults use apps to improve their
            productivity, but mobile apps can also support children's
            development.
          </p>
          <br />
          <p>
            We, the {" "}<str-color-green>OSSystem</str-color-green>{" "} team, have
            extensive experience in mobile app development and would like to
            share our experience in developing mobile apps for children and
            their parents that help engage children in active play and why it is
            worth developing apps for children.
          </p>
        </div>
        <div className="overview-container">
          <h2 className="pickup-sport-title overview-subtitle overview-client">
            Client
          </h2>
          <div className="overview-content">
            <div className="overview-text overview-text-client">
              A modern sports start-up for children and their parents (
              <a
                href="https://pickupsports.co/pages/app-1"
                className="overview-text__link"
                target="_blank"
                rel="noreferrer"
              >
                read more
              </a>
              ) that aims to gamify the process of organizing active children's
              games, help children lead healthy lifestyles, and improve their
              communication.
            </div>
            <div className="overview-customer-title">Customer Challenge</div>
            <div className="overview-customer-text">
              To develop a mobile app that allows parents to register, add their
              children, organize and participate in sporting activities, and
              specify their location to collaborate with other parents and
              organize games together.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
