import React from 'react';
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Navigate from "../../components/SingleCase/Navigate"
import { mainUrl } from "../../js/config"
import RelatedProjects from "../../components/SingleCase/RelatedProjects";
import PickUpSport from "../../components/SingleCase/PickUpSport";
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise";
import dataForPichUpSport from '../../information/Cases/dataForPickUpSport';
import { Sharing } from '../../images/SingleCase/PickUpSport';

const PickUp = () => {

  const {
    classComponent,
    nextLink,
    prevLink,
    relatedProjects,
    data,
  } = dataForPichUpSport;

  const sendUrl = typeof window !== "undefined" ? window?.location?.href : "";

  return (
    <section className="singleProject">
      <SEO
        title="Sport Activities Mobile App Case - Os-System"
        description="A modern sports start-up for children and their parents that aims to gamify the process of organizing active children's games and improve their communication"
        canonical={`${mainUrl}/cases/pickup-sport/`}
        ogImage={`${mainUrl}${Sharing}`}
      />
      <Layout>
        <PickUpSport data={data} />
        <Navigate
          classComponent={classComponent}
          nextLink={nextLink}
          prevLink={prevLink}
        />
        <RelatedProjects relatedProjects={relatedProjects} />
        <div id="anchor-form">
          <ContactUsExpertise form={0} url={sendUrl} />
        </div>
      </Layout>
    </section>
  );
};

export default PickUp;