import React, { useEffect, useRef } from "react"
import { Link } from "react-scroll"

export default function Solution({ solution }) {
  const videoRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      const videoRect = videoRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (
        videoRect.top >= -350 &&
        videoRect.bottom <= windowHeight + 150
      ) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div  className="solution">
      <div className="wrapper">
        <div className="d-flex flex-column flex-lg-row p-0 solution-block col-12">
          <h2 className="pickup-sport-title solution-title col-12 col-lg-3">
            Solution
          </h2>

          <div className="solution-text p-0 col-12 col-lg-10">
            The team decided to discard the existing application code because it
            was not in line with best practices and was difficult to scale,
            which could lead to problems when expanding the project. The
            decision was made to build the application from scratch in order to
            better meet user needs and create a better product.
          </div>
        </div>

        <div className="solution-container">
          <div className="flex-column flex-md-row solution-content col-12 p-0">
            <div className="solution-left-side col-12 col-md-6 p-0">
              <div className="solution-content__text">
                We offered design and development services for the backend,
                mobile app, and admin panel during our collaboration, and
                implemented the following features at different stages.
              </div>
              <div className="solution-content__text mb-0">
                Design <br />
                Management and Testing
              </div>
              <ul className="solution-list">
                {solution.stages.map(stage => (
                  <li className="solution-list__item" key={stage.id}>
                    <div className="solution-list__item-num">{stage.id}.</div>
                    <div className="solution-list__item-text">{stage.text}</div>
                  </li>
                ))}
              </ul>
              <div className="solution-content__text">
                Overall Improvements <br />
                Release
              </div>
            </div>
            <div  className="solution-right-side col-12 col-md-6 p-0">
              <video
                ref={videoRef}
                autoPlay
                poster={solution.posterVideo}
                loop
                muted
                playsInline
              >
                <source src={solution.video} alt={"video"} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        <div className="solution-bottom solution-content__text">
          The design was based on wireframes provided by the client.
          Authorization was set up using four different methods: Google, Apple
          ID, SMS, or email.
          <br />
          <span className="solution-content__subtext">
            Soulfulness and originality were provided by the images of
            children's games, hand-drawn by the client's children, which were
            added to the tutorial.
          </span>
        </div>
      </div>
      <div className="solution-contact">
        <div className="solution-contact__text">
          Looking for a similar solution for your business? Tell us more about
          your <br /> business
        </div>
        <div className="solution-contact__btn">
          <Link
            className=""
            to="anchor-form"
            spy={true}
            smooth={"easeOutSine"}
            offset={10}
            duration={1000}
          >
            Tell us what you need
          </Link>
        </div>
      </div>
    </div>
  )
}
