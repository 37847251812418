import React from "react"
import { Link } from "react-scroll"

export default function Problems({ problems }) {
  return (
    <div className="problems">
      <div className="wrapper">
        <h2 className="pickup-sport-title problems-title">Problems and their causes</h2>
        <div className="problems-text">
          Developing an application for children and their parents that
          encourages active play helps to lead <br /> a healthy lifestyle, improve
          communication skills, and make new friends. And also{" "}
          <span className="problems-text-green">
             solve the following<br /> problems:
          </span>
        </div>
        <div className="d-flex p-0 flex-column flex-lg-row problems-blocks col-12">
          <div className="problems-blocks-picture d-flex p-0 justify-content-center justify-content-lg-start col-12 col-lg-5 ">
            <img className="problems-blocks-image" src={problems.image} alt="" />
          </div>
          <div className="d-flex p-0 flex-column align-items-center align-items-lg-start col-12 col-lg-7">
            {problems.content.map(content => (
              <div key={content.id} className="panel w-100">
                <span className="panel-text">{content.text}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="problems-contact">
          <div className="problems-contact__text">
            Are you familiar with these problems? Tell us more about your
            problem and <br /> our specialists will contact you
          </div>
          <div className="problems-contact__btn">
            <Link
              className=""
              to="anchor-form"
              spy={true}
              smooth={"easeOutSine"}
              offset={10}
              duration={1000}
            >
              Tell us about your problem
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
