import React from 'react';

import './PickUpSport.scss';
import TopBanner from './TopBanner';
import Overview from './Overview';
import Problems from './Problems';
import Challenge from './Challenge';
import Solution from './Solution';
import Result from './Result';
import Benefits from './Benefits';

export default function PickUpSport ({data}) {
  return (
    <div className="pikup-sport-container">
      <TopBanner topbanner={data.top_banner} />
      <Overview />
      <Problems problems={data.problems} />
      <Challenge challenge={data.challenge} />
      <Solution solution={data.solution} />
      <Result result={data.result} />
      <Benefits benefits={data.benefits} />
    </div>
  );
};
